import React from 'react';
import './footer.css';
import gpt3logo from '../../assets/logo.svg'

const Footer = () => {
  return (
    <div className='gpt3__footer section__padding'>
      <div className="gpt3__footer-heading">
        <h1 className="gradient__text">Do you want to step in to the future before other?</h1>
      </div>
      <div className="gpt3__footer-btn">
        <p>Request Early Access</p>
      </div>

      <div className="gpt3__footer-links">
        <div className="gpt3__footer-links_logo">
          <img src={gpt3logo} alt="" />
          <p>Copyright something. All rights Reserved 2021</p>
        </div>

        <div className="gpt3__footer-links_div">
          <h4>Links</h4>
          <p>Overons</p>
          <p>Social Media</p>
          <p>Counters</p>
          <p>Contacts</p>
        </div>

        <div className="gpt3__footer-links_div">
          <h4>Comapny</h4>
          <p>Terms and Conditions</p>
          <p>Privacy Policy</p>
          <p>Contacts</p>
        </div>

        <div className="gpt3__footer-links_div">
          <h4>Get in touch</h4>
          <p>Fake house no. 456, Made up Street, Fucked up Nation, 449109</p>
          <p>098-812719</p>
          <p>fake.emailid@fuckoff.cum</p>
        </div>
      </div>

      <div className="gpt3__footer-copyright">
        <p>C 2021-GPT-3. All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer