import React from 'react';
import './possibility.css';
import possibilityimage from '../../assets/possibility.png';

const Possibility = () => {
  return (
    <div className='gpt3__possibility section__padding' id='possibility'>
      <div className="gpt3__possibility-image">
        <img src={possibilityimage} alt="" />
      </div>
      <div className="gpt3__possibility-content">
        <h4>Rquest Early Access to get Started</h4>
        <h1 className="gradient__text">
          The possibilites are beyound your imaginantion here
        </h1>
        <p>Something very sci-fi that can blow your mind of i am not even lying like i have spent two whole days and i still dont get shit what GPT-3 is.</p>
        <h4>Rquest Early Access to get Started</h4>
      </div>
    </div>
  )
}

export default Possibility