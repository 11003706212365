import React from 'react';
import './whatGPT3.css';
import {Feature} from '../../components';

const WhatGPT3 = () => {
  return (
    <div className='gpt3__whatgpt3 section__margin' id='wgpt3'>
      <div className="gpt3__whatgpt3-feature">
        <Feature title='What is GPT-3' text='GPT-3 is revolutionary idea that i dont know about but i need to write something in order to fill the space so i hope this much of bullshit is enoguh because idk what else to type anymore and tbh it is getting boring now.'/>
      </div>
      <div className="gpt3__whatgpt3-heading">
        <h1 className='gradient__text'>Possibilities are beyound your imagination</h1>

        <p>Explore the Library</p>
      </div>
      <div className="gpt3__whatgpt3-container">
        <Feature title='Chatbots' text='it is something you can chat with and it is a bot. very self explanatory if you have any kind of common sense in you.'/>
        <Feature title='Knowledgebase' text='Tbh i dont have a clue what this is but i m going to play cool and just say that it has something to with database.'/>
        <Feature title='Education' text='i think this GPT-3 helps with education sector somehow. idk how tho and i also dont care so thats that.'/>
      </div>
    </div>
  )
}

export default WhatGPT3