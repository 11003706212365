import React from 'react';
import './features.css';
import {Feature} from '../../components';

const featuresData = [
  {
    title: 'Improving your surffing environment',
    text: 'Dude like istg i dont think i can write so many different things like i just cant ok but it is what it is i am getting fed up now.'
  },
  {
    title: 'Become the tended active',
    text: 'Dude like istg i dont think i can write so many different things like i just cant ok but it is what it is i am getting fed up now.'
  },
  {
    title: 'Something very futuristic',
    text: 'Dude like istg i dont think i can write so many different things like i just cant ok but it is what it is i am getting fed up now.'
  },
  {
    title: 'What is even the purpose of this',
    text: 'Dude like istg i dont think i can write so many different things like i just cant ok but it is what it is i am getting fed up now.'
  }
]

const Features = () => {
  return (
    <div className='gpt3__features section__padding' id='feature'>
      <div className="gpt3__features-heading">
        <h1 className="gradient__text">
          This something realted to the features the GPT-3 provides and i couldn't care less.
        </h1>
        <p>Request Early Access to get Started</p>
      </div>
      <div className="gpt3__features-container">
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title+index}/>
        )) }
      </div>
    </div>
  )
}

export default Features